
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import Qrious from "qrious";
import { Mixin } from "@/core/mixins/mixin";

@Component
export default class VueQrcode extends Mixins(Mixin) {
  @Prop({
    type: String,
    default: "white",
  })
  public background!: string;
  @Prop({
    type: Number,
    default: 0.0,
  })
  public backgroundAlpha!: number;
  @Prop({
    type: String,
    default: "black",
  })
  public foreground!: string;
  @Prop({
    type: Number,
    default: 1.0,
  })
  public foregroundAlpha!: number;
  @Prop({
    type: String,
    default: "L",
  })
  public level!: string;
  @Prop({
    type: String,
    default: "image/png",
  })
  public mime!: string;
  @Prop({
    type: Number,
    default: null,
  })
  public padding!: number;
  @Prop({
    type: Number,
    default: 100,
  })
  public size!: number;
  @Prop({
    type: String,
    required: true,
  })
  public value!: string;

  qrious: any = null;

  @Watch("background")
  getBackground(): void {
    this.qrious.background = this.background;
  }
  @Watch("backgroundAlpha")
  getBackgroundAlpha(): void {
    this.qrious.backgroundAlpha = this.backgroundAlpha;
  }
  @Watch("foreground")
  getForeground(): void {
    this.qrious.foreground = this.foreground;
  }
  @Watch("foregroundAlpha")
  getForegroundAlpha(): void {
    this.qrious.foregroundAlpha = this.foregroundAlpha;
  }
  @Watch("level")
  getLevel(): void {
    this.qrious.level = this.level;
  }
  @Watch("mime")
  getMime(): void {
    this.qrious.mime = this.mime;
  }
  @Watch("padding")
  getPadding(): void {
    this.qrious.padding = this.padding;
  }
  @Watch("size")
  getSize(): void {
    this.qrious.size = this.size;
  }
  @Watch("value")
  getValue(): void {
    this.qrious.value = this.value;
  }

  mounted(): void {
    const element = this.$refs.qrcode;
    const background = this.background;
    const backgroundAlpha = this.backgroundAlpha;
    const foreground = this.foreground;
    const foregroundAlpha = this.foregroundAlpha;
    const level = this.level;
    const mime = this.mime;
    const padding = this.padding;
    const size = this.size;
    const value = this.value;

    this.qrious = new Qrious({
      element,
      background,
      backgroundAlpha,
      foreground,
      foregroundAlpha,
      level,
      mime,
      padding,
      size,
      value,
    });
  }
}
