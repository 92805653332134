/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @desc 各端支持的支付方式
 */
import { defaultSuccess, defaultError, http } from "@/plugins/axios";

export class PaymentWaysParams {
  constructor(
    public channel?:
      | "PLAT_H5"
      | "PLAT_H5_WX"
      | "PLAT_PC"
      | "PLAT_IOS"
      | "PLAT_ANDROID"
      | "DOUYIN_MINIAPP"
      | "CHINA_BANK"
      | "PLAT_WECHAT_MINI_APP"
      | "THEATRE_PC"
      | "THEATRE_H5"
      | "THEATRE_H5_WX"
      | "THEATRE_WX_MINI_APP"
      | "THEATRE_PC_ZHONGSAHN"
      | "THEATRE_PC_DONGYI"
  ) {}
}

export default async function (
  params: PaymentWaysParams,
  success: ({
    data,
    ext,
    state,
    errors,
  }: {
    data: Array<payment.PaymentWayDTO>;
    ext: ObjectMap;
    state: "SUCCESS" | "FAIL" | "EXCEPTION";
    errors?: Array<string>;
  }) => void = defaultSuccess,
  fail: (error: string) => void = defaultError
): Promise<void> {
  return http({
    method: "get",
    url: `/payment/ways`,

    params,
  })
    .then((data) => success(data as any))
    .catch((error) => fail(error));
}
